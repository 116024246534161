﻿@import "mixins/common";
@import "mixins/group-control";

.dxbl-accordion-group {
    --dxbl-accordion-group-header-spacing: #{$dx-accordion-group-header-spacing};
    --dxbl-accordion-group-header-bg: #{$dx-accordion-group-header-bg};
    --dxbl-accordion-group-header-color: #{$dx-accordion-group-header-color};
    --dxbl-accordion-group-header-hover-bg: #{$dx-accordion-group-header-hover-bg};
    --dxbl-accordion-group-header-hover-color: #{$dx-accordion-group-header-hover-color};
    --dxbl-accordion-group-header-selected-bg: #{$dx-accordion-group-header-selected-bg};
    --dxbl-accordion-group-header-selected-color: #{$dx-accordion-group-header-selected-color};
    --dxbl-accordion-group-item-spacing: #{$dx-accordion-group-item-spacing};
    --dxbl-accordion-group-item-content-padding-x: var(--dxbl-group-header-padding-x);

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;

    &.dxbl-group {
        --dxbl-group-header-spacing: var(--dxbl-accordion-group-header-spacing);
    }

    &:not(:first-child) {
        border-top: 0;

        &:not(:last-child) {
            &,
            & > .dxbl-accordion-group-header {
                border-radius: 0;
            }
        }

        &:last-child {
            &,
            & > .dxbl-accordion-group-header {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    &:first-child:not(:last-child) {
        &,
        & > .dxbl-accordion-group-header {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    & .dxbl-accordion-group-header {
        & > .dxbl-accordion-item-content:not(:last-child) {
            margin-right: var(--dxbl-accordion-group-header-spacing);
        }

        & > .dxbl-accordion-item-content {
            display: flex;
            text-decoration: none;
            position: relative;
            flex-grow: 1;
            color: inherit;
        }
    }

    &:not(.dxbl-disabled):not(.dxbl-accordion-filter) {
        &:not(.dxbl-active) > .dxbl-accordion-group-header {
            cursor: pointer;
        }

        & > .dxbl-accordion-group-header:hover {
            @include panel-header-hover-style("--dxbl-accordion-group-header-hover-bg", "--dxbl-accordion-group-header-hover-color");
        }

        @include active-state() {
            & > .dxbl-accordion-group-header {
                @include panel-header-selected-style("--dxbl-accordion-group-header-selected-bg", "--dxbl-accordion-group-header-selected-color");
            }
        }
    }

    &:not(.dxbl-accordion-filter) {
        &.dxbl-active > .dxbl-accordion-group-header,
        & > .dxbl-accordion-group-header:hover,
        & > .dxbl-accordion-group-header:active {
            & a.dxbl-accordion-item-content {
                color: inherit;
            }
        }

        & > .dxbl-accordion-group-header {
            align-items: center;
        }
    }

    &.dxbl-accordion-filter > .dxbl-accordion-group-header {
        .dxbl-navigation-filter.dxbl-text-edit {
            --dxbl-accordion-filter-box-padding-y: 0.125rem;
            --dxbl-text-edit-padding-y: var(--dxbl-accordion-filter-box-padding-y);
            --dxbl-text-edit-btn-padding-y: var(--dxbl-accordion-filter-box-padding-y);

            > .dxbl-btn-icon {
                padding-left: 0;
            }
        }
    }

    @include disabled-state() {
        cursor: default;

        .dxbl-accordion-group-header {
            & .dxbl-accordion-item-text-container:not(.dxbl-accordion-item-text-tmpl) {
                opacity: $dx-accordion-group-disabled-opacity;
            }
        }
    }

    & .dxbl-accordion-item-content {
        & .dxbl-accordion-group-expand-btn-container + * {
            margin-left: var(--dxbl-accordion-group-item-spacing);
        }

        & > .dxbl-accordion-item-text-container:not(:last-child) {
            margin-right: var(--dxbl-accordion-group-item-spacing);
        }

        & > .dxbl-accordion-item-text-container {
            @include group-text-container-style();
        }
    }

    & > .dxbl-accordion-group-body {
        & .dxbl-accordion-item-content {
            padding: 0 var(--dxbl-accordion-group-item-content-padding-x);

            & .dxbl-accordion-group-expand-btn-container {
                @include group-expand-button-style() {
                    --dxbl-btn-hover-bg: transparent;
                    --dxbl-btn-active-bg: transparent;
                    --dxbl-btn-focus-shadow-color: transparent;
                    --dxbl-btn-focus-shadow-spread: 0;
                    --dxbl-btn-hover-color: currentcolor;
                    --dxbl-btn-hover-background: transparent;
                }
            }
        }
    }
}
